import getConfig from "next/config";
import { useRouter } from "next/router";
import translation from "./locale/index.json";
import getLanguage from "@/app/utils/getlanguage";
import SeoHeader from "@/app/components/SeoHeader";
import { replaceDomain } from "@/app/utils/replaceDomain";
import Footer from "@/app/components/Footer";
import CookiesWidget from "@/app/components/CookiesWidget";
import ServiceChat, { IServiceChat } from "@/app/components/Services/Text/Chat";
import NavBar from "@/app/components/NavBar";
import { Col, Grid } from "@tremor/react";
import Image from "next/image";
import marcin from "../public/images/marcin.jpeg";

export default function IndexPage(props: { cookiesPP: boolean; userInfo: IServiceChat }) {
  const { cookiesPP, userInfo } = props;
  const { publicRuntimeConfig } = getConfig();
  const { locale } = useRouter();
  const lang = getLanguage(translation, locale);
  const APP_HOST = publicRuntimeConfig.APP_HOST || "http://localhost:3000";
  const content = lang.content;
  const headers = replaceDomain(lang.headers, APP_HOST, "XXX");

  userInfo.content.greeting = content.greeting;
  userInfo.minHeight = "40vh";
  userInfo.maxHeight = "40vh";

  return (
    <>
      <SeoHeader
        title={headers.title}
        description={headers.description}
        keyWords={headers.keyWords}
        openGraph={headers.openGraph}
        canonicalLink={headers.canonicalLink}
        metaRobots={headers.metaRobots}
        jsonLd={headers.jsonLd}
        // preloadImgLinks={text}
      />
      <div className="bg-gradient-to-br from-blue-100 via-purple-50 to-green-50">
        <section className="min-h-screen min-w-screen " dir={locale === "ar" ? "rtl" : "ltr"}>
          <div className="mx-auto p-2 sm:p-2 md:p-5 lg:p-5 pt-[15%] flex sticky top-[25%] items-center justify-center text-center text-6xl md:text-7xl lg:text-9xl">
            {content.sloganOne}
          </div>
        </section>

        <section className="min-h-[400px] min-w-screen" dir={locale === "ar" ? "rtl" : "ltr"}>
          <div className="mx-auto p-5 flex sticky top-[5%] items-center justify-center text-center text-6xl md:text-7xl lg:text-9xl">
            {content.sloganTwo}
          </div>
        </section>

        
        <div className="container mx-auto h-screen" dir={locale === "ar" ? "rtl" : "ltr"}>
        <NavBar />
          <section className="w-full sm:pt-[45vh]">
            <div className="grid grid-cols-1">
              <div className="mx-auto sm:mt-[2%] mr-[5%] mb-4">
                <div className="sm:mt-4 text-black-300">
                  <Grid numItems={1} numItemsSm={12} className="w-screen sm:w-full gap-2 px-auto pr-4 pl-4">
                    <Col numColSpanSm={3} numColSpan={1} className="mx-auto my-auto mt-4">
                      <Image src={marcin} alt="Marcin" width={200} height={200} className="rounded-[50%]" />
                      <div className="mx-auto w-full mt-4 text-center">
                        Marcin Kania <br />
                        {content.founder}
                      </div>
                    </Col>

                    <Col
                      numColSpan={1}
                      numColSpanSm={9}
                      className="w-screen max-w-full min-h-[40vh] max-h-[40vh] text-xl sm:text-2xl text-wrap"
                    >
                      <ServiceChat userInfo={userInfo} />
                    </Col>
                  </Grid>
                </div>
              </div>
            </div>
          </section>
        </div>

        {!cookiesPP ? <CookiesWidget /> : ""}
        <div className=" text-white relative bottom-0 w-screen mt-[15vh]">
          <div className="pointer-events-none flex h-[50px] translate-y-0.5 justify-center overflow-hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2500"
              height="50"
              viewBox="0 0 2500 65"
              className="absolute bottom-0 fill-bluewood"
            >
              <path d="M 2019.58 14.03 C 1799.7 28.11 1670.77 106.46 1269.53 30.22 C 952.2 -30.07 684 12.76 408.73 47.37 C 273.12 64.42 130.79 66.63 0 66.86 V 68.86 H 2500 V 66.86 C 2371.07 66.86 2288.68 1.17 2019.58 14.03 Z"></path>
            </svg>
          </div>

          <div className="text-white bg-bluewood">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getServerSideProps(context: any) {
  const cookiesPP = context?.req?.cookies;
  const userAgent = context.req.headers["user-agent"] || null;
  const referer = context.req.headers["referer"] || null;
  const language = context.req.headers["accept-language"] || null;

  const userInfo: IServiceChat = {
    userAgent,
    referer,
    language,
    content: {
      greeting: "",
    },
  };

  return {
    props: { cookiesPP: cookiesPP["p4u-cookies"] || false, userInfo }, // will be passed to the page component as props
  };
}
